import 'chart.js'

// display loaded popup windows
(function ($) {
    $.fn.select2.defaults.set("language", "de"); // might read this from document if required
    $(document).ready(function () {
        $.each(window.backendPopupLoader, function (key, value) {

            $("<div></div>").window({
                action: value.action,
                id: value.id,
                selected: value.selected,
                module: value.module,
                position: value.position,
                state: value.state,
                height: value.height,
                width: value.width
            });

        });
    });
})(jQuery);

// Chart configuration
Chart.defaults.global.maintainAspectRatio = false;
Chart.defaults.global.legend.fullWidth = false;
Chart.defaults.global.legend.labels.boxWidth = 24;
Chart.defaults.doughnut.legend.position = "right";
Chart.defaults.global.animation.duration = 3000;
Chart.defaults.global.elements.line.borderWidth = 1;
Chart.defaults.global.elements.rectangle.borderWidth = 1;
Chart.defaults.global.elements.arc.borderWidth = 1;
Chart.defaults.global.elements.arc.borderColor = 'rgba(255,255,255,0.8)';
Chart.defaults.global.defaultFontColor = '#000';